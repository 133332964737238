<template>
  <div>
    <BasicCreateEditContainer
      :title="mode == 'create' ? t('create.title') : t('edit.title')"
      ref="editor"
      object-type="Training"
      :object-name="t('card-title')"
      :data-loaded="dataLoaded"
      @cancel="close"
      :save-action="saveTraining"
      @saved="onSaved"
      :allow-delete="mode == 'edit'"
      :delete-action="deleteTraining"
      @deleted="onDeleted"
      :has-changes="hasChanges"
      :hidden-field-validation-message="t('validation.hidden-field-validation-lessons')"
    >
      <template v-slot:content>
        <v-card elevation="0">
          <v-card-title>{{ t('card-title') }}</v-card-title>
          <v-card-text>

            <v-text-field
              :label="t('title-placeholder')"
              v-model="training.title"
              counter="40"
              filled
              :rules="[
                () => !!training.title || t('validation.error-title-required'),
                () => (training.title && training.title.length <= 40) || t('validation.error-max-title-40')
              ]"
            ></v-text-field>

            <v-textarea
              :label="t('description-placeholder')"
              v-model="training.description"
              counter="400"
              filled
              :rules="[
                () => !!training.description || $t('validation.required.description'),
                () => (training.description && training.description.length <= 400) || t('validation.error-max-description-400')
              ]"
            ></v-textarea>

            <v-text-field
              ref="hiddenValidation"
              class="hidden"
              v-model="noLessonsValue"
              :rules="[
                () => !!noLessonsValue || t('validation.hidden-field-validation-lessons')
              ]"
            ></v-text-field>

          </v-card-text>
        </v-card>

        <v-card v-if="editLessonIndex !== null" elevation="0" class="sp-mar-top-3">
          <v-card-title>
            <b>{{ getLessonPrefix(editLessonIndex) }}</b>
            <v-btn
              elevation="0"
              color="primary"
              outlined
              class="icon-left mr-2"
              @click="cancelEditLesson()"
            >
              <v-icon>mdi-check</v-icon>
              {{ t('lessons.close') }}
            </v-btn>
          </v-card-title>
          <v-card-text>

            <v-text-field
              :label="t('title-placeholder')"
              v-model="training.lessons[editLessonIndex].title"
              counter="40"
              filled
              :rules="[
                () => !!training.lessons[editLessonIndex].title || t('validation.error-title-required'),
                () => (training.lessons[editLessonIndex].title && training.lessons[editLessonIndex].title.length <= 40) || t('validation.error-max-title-40')
              ]"
            ></v-text-field>

            <content-editor content-type="learning-portal" v-model="training.lessons[editLessonIndex].blocks" />

            <div v-if="training.lessons[editLessonIndex].quizes.length > 0" class="quizes-wrapper sp-mar-top-2">
              <div v-for="(quiz, qindex) in training.lessons[editLessonIndex].quizes" :index="qindex">

                <v-card elevation="0">
                  <v-card-title>
                    {{ t('lessons.types.' + quiz.type + '-title') }}
                    <span class="move-block">
                      <span
                        v-if="qindex > 0"
                        @click="moveUp(qindex)"
                        class="moveup-block"
                        :title="$t('components.blocks-editor.moveup')"
                      >
                        <v-icon>mdi-arrow-up-thin</v-icon>
                      </span>
                      <span
                        v-if="qindex < training.lessons[editLessonIndex].quizes.length - 1"
                        @click="moveDown(qindex)"
                        class="movedown-block"
                        :title="$t('components.blocks-editor.movedown')"
                      >
                        <v-icon>mdi-arrow-down-thin</v-icon>
                      </span>
                    </span>
                    <span
                      @click="removeQuiz(qindex)"
                      class="remove-block"
                      :title="$t('components.blocks-editor.remove')"
                    >
                      <v-icon>mdi-delete-outline</v-icon>
                    </span>
                  </v-card-title>

                  <v-card-text>

                    <v-text-field
                      :label="t('title-placeholder')"
                      v-model="quiz.title"
                      counter="120"
                      filled
                      :rules="[
                        () => !!quiz.title || t('validation.error-title-required'),
                        () => (quiz.title && quiz.title.length <= 120) || t('validation.error-max-title-120')
                      ]"
                    ></v-text-field>

                    <!-- Einzelauswahl -->
                    <div v-if="quiz.type === 'single_select'" class="quiz-content">
                      <div class="quiz-content-hint">
                        <v-icon>mdi-information-outline</v-icon>
                        {{ t('lessons.types.single_select-hint') }}
                      </div>
                      <div class="selected-options-list-wrapper">
                        <div class="sp-mar-bot-1 selected-options-list">
                          <div v-for="(option, oindex) in quiz.options" :key="oindex">
                            <v-icon @click="removeOption(qindex, oindex)" color="primary">mdi-minus-box</v-icon>
                            <span>{{ option.value }}</span>
                          </div>
                        </div>
                        <div class="selected-options-list-answers">
                          <v-radio-group v-model="quiz.selectedAnswer" @change="radioChanged($event, qindex)" column>
                            <v-radio
                              v-for="(option, oindex) in quiz.options"
                              :key="oindex"
                              :value="oindex"
                            ></v-radio>
                          </v-radio-group>
                        </div>
                      </div>  
                      <div class="add-option">
                        <v-icon @click="addOption(qindex)" color="primary">mdi-plus-box</v-icon>
                        <v-text-field
                          v-model="quiz.selectedOption"
                          dense
                          hide-details="auto"
                        >
                        </v-text-field>
                      </div>
                    </div>

                    <!-- Mehrfachauswahl -->
                    <div v-if="quiz.type === 'multi_select'" class="quiz-content">
                      <div class="quiz-content-hint">
                        <v-icon>mdi-information-outline</v-icon>
                        {{ t('lessons.types.multi_select-hint') }}
                      </div>
                      <div class="selected-options-list-wrapper">
                        <div class="sp-mar-bot-1 selected-options-list">
                          <div v-for="(option, oindex) in quiz.options" :key="oindex">
                            <v-icon @click="removeOption(qindex, oindex)" color="primary">mdi-minus-box</v-icon>
                            <span>{{ option.value }}</span>
                          </div>
                        </div>
                        <div class="selected-options-list-answers checkbox">
                          <v-checkbox
                            v-for="(option, oindex) in quiz.options"
                            v-model="option.is_answer"
                            :key="oindex"
                          ></v-checkbox>
                        </div>
                      </div>  
                      <div class="add-option">
                        <v-icon @click="addOption(qindex)" color="primary">mdi-plus-box</v-icon>
                        <v-text-field
                          v-model="quiz.selectedOption"
                          dense
                          hide-details="auto"
                        >
                        </v-text-field>
                      </div>
                    </div>

                  </v-card-text> 
                </v-card>

              </div>  
            </div>

          </v-card-text>

          <v-card-actions>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  elevation="0"
                  color="primary"
                  outlined
                  class="icon-left"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-plus</v-icon>
                  {{ t('lessons.add-slide') }}
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(quiz, index) in quizTypes"
                  :key="index"
                  class="blocks-list-item"
                  @click="addQuiz(quiz.type)"
                >
                  <v-list-item-title>
                    <v-icon>{{ quiz.icon }}</v-icon>
                    {{ quiz.name }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-spacer></v-spacer>
            <v-btn
              elevation="0"
              color="primary"
              outlined
              class="icon-left"
              @click="cancelEditLesson()"
            >
              <v-icon>mdi-check</v-icon>
              {{ t('lessons.close') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>

      <template v-slot:meta>
        <v-card elevation="0">
          <v-card-title>{{ t('active.card-title') }}</v-card-title>
          <v-card-text>
  
            <v-switch
              v-model="training.is_active"
              :label="training.is_active ? t('active.yes') : t('active.no')"
              color="primary"
              hide-details
            ></v-switch>
  
          </v-card-text>
        </v-card>

        <v-card elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ t('folder-select.card-title') + '*' }}</v-card-title>
          <v-card-text>
            
              <learning-portal-folder-selector
                  v-if="dataLoaded"
                  v-model="training.folders"
                  :required="true"
              ></learning-portal-folder-selector>

          </v-card-text>
        </v-card>

        <v-card elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ t('required-answers.card-title') }}</v-card-title>
          <v-card-text>

            <div class="allowed-tries-wrapper"> 
              <p>{{ t('required-answers.quizes-count') }} {{ amountOfQuizes }}</p>

              <v-text-field
                class="mt-0 pt-0"
                type="number"
                :label="t('required-answers.correct-value')" 
                v-model.number="training.required_correct_answers"
                min="0"
                :max="amountOfQuizes"
                hide-details="auto"
                @keyup="validateNumberField('required_correct_answers')"
              ></v-text-field>
            </div>

          </v-card-text>
        </v-card>

        <v-card elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ t('allowed-tries.card-title') }}</v-card-title>
          <v-card-text>
            
            <div class="allowed-tries-wrapper">
              <v-switch
                v-model="training.allowed_tries_enabled"
                :label="training.allowed_tries_enabled ? t('allowed-tries.yes') : t('allowed-tries.no')"
                color="primary"
                hide-details
                @change="allowedTriesChanged($event)"
              ></v-switch>

              <v-text-field
                v-if="training.allowed_tries_enabled"
                class="mt-0 pt-0"
                type="number"
                :label="t('allowed-tries.max-value')" 
                v-model.number="training.allowed_tries"
                hide-details="auto"
                min="1"
                @keyup="validateNumberField('allowed_tries')"
              ></v-text-field>
            </div>

          </v-card-text>
        </v-card>

        <v-card elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ t('lessons.card-title') + '*' }}</v-card-title>
          <v-card-text>

            <div class="lessons-wrapper">
              <draggable element="div" v-model="training.lessons" :sort="true">
                <div class="drag-element" v-for="(lesson, index) in training.lessons" :key="index">
                  <v-icon class="drag-handle">mdi-drag</v-icon>
                  <div class="title-label"><b>{{ getLessonPrefix(index) }}</b>{{ lesson.title }}</div>
                  <v-icon class="lesson-trash" @click="removeLesson(index)">mdi-trash-can-outline</v-icon>
                  <v-icon class="lesson-edit" @click="editLesson(index)">edit</v-icon>
                </div>
              </draggable>
              <div v-if="editLessonIndex !== null" class="drag-disable-wrapper"></div>
            </div>
            
            <div class="float-right" :class="[{ 'sp-mar-top-2': training.lessons && training.lessons.length > 0 }]">
              <v-btn
                  elevation="0"
                  color="primary"
                  outlined
                  class="icon-left"
                  @click="addLesson()"
              >
                <v-icon>mdi-plus</v-icon>
                {{ t('lessons.add-lesson') }}
              </v-btn>
          </div>

          </v-card-text>
        </v-card>
      </template>

    </BasicCreateEditContainer>
  </div>
</template>
  
<script>
  import { HTTP } from '@/auth'
  import draggable from 'vuedraggable'
  import BasicCreateEditContainer from '@/components/inputs/BasicCreateEditContainer'
  import LearningPortalFolderSelector from '@/views/LearningPortalFolderSelector'
  import PictureUpload from '@/components/inputs/PictureUpload'
  import ContentEditor from '@/components/ContentEditor'

  export default {
    name: 'LearningPortalTrainingsCreateEdit',
    components: {
      draggable,
      BasicCreateEditContainer,
      LearningPortalFolderSelector,
      PictureUpload,
      ContentEditor
    },
    data () {
      return {
        training: {
          id: null,
          title: '',
          is_active: true,
          folders: [],
          lessons: [],
          required_correct_answers: 0,
          allowed_tries: 1,
          allowed_tries_enabled: 0
        },
        editLessonIndex: null,
        dataOriginal: null,
        dataLoaded: false,
        mode: null,
        error: null,
        refresh: true
      }
    },
    created() {
      this.training.id = this.$route.params.id
      if (this.training.id) {
        this.getTraining()
        this.mode = 'edit'
      } else {
        this.mode = 'create'
        this.dataOriginal = JSON.parse(JSON.stringify(this.training))
        this.dataLoaded = true
      }
    },
    computed: {
      hasChanges() {
        return JSON.stringify(this.training) !== JSON.stringify(this.dataOriginal)
      },
      quizTypes() {
        return [
          {
            type: 'single_select',  
            name: this.$t('learning-portal.trainings.lessons.types.single_select-type'),
            icon: 'mdi-radiobox-marked'
          },
          {
            type: 'multi_select',
            name: this.$t('learning-portal.trainings.lessons.types.multi_select-type'),
            icon: 'mdi-checkbox-intermediate'
          }
        ]
      },
      amountOfQuizes() {
        let amount = 0
        this.training.lessons.forEach((lesson) => {
          amount += lesson.quizes.length
        })
        return amount
      },
      hasLessons() {
        return this.training.lessons.length !== 0
      },
      noLessonsValue() {
        return this.hasLessons ? 'hasLessons' : null
      }
    },
    methods: {
      t: function (key) {
        return this.$t('learning-portal.trainings.' + key)
      },
      getTraining() {
        HTTP.get('learning_portal_training/' + this.training.id).then(function (response) {
          this.training = response.data
          this.$set(this.training, 'allowed_tries_enabled', this.training.allowed_tries !== -1)
          this.dataOriginal = JSON.parse(JSON.stringify(this.training))
          this.dataLoaded = true;
        }.bind(this)).catch(function (error) {
          console.log(error)
          /*if (error.response.status && error.response.status === 404) {
              this.$root.infoNotification.showMessage(this.$t('learning-portal.trainings.validation.not-found', { trainingId: this.training.id }))
          } else {
            this.$root.infoNotification.showMessage(this.$t('learning-portal.trainings.validation.unknown-error') + error.response.statusText)
          }
          this.close()*/
        }.bind(this))
      },
      saveTraining() {

        // todo: validate Lektionen ?
        // todo: validate Quiz -> Optionen/Answers ?

        let formData = new FormData()

        this.training.lessons.forEach((lesson, lindex) => {
          let order = 0
          lesson.blocks.forEach(block => {
            if (block.name === 'Video' && block.new_content) {
              block.order = lindex + '-' + order
              formData.append('video-' + block.order, block.video)
              order++
            }
          })
        })

        if (!this.training.allowed_tries_enabled) {
          this.training.allowed_tries = -1
        }

        const config = {
          headers: {'content-type': 'multipart/form-data'}
        }

        formData.append('data', JSON.stringify(this.training))

        if (this.mode == 'create') {
          return HTTP.post('learning_portal_training', formData, config).then(function (response) {
            //
          }.bind(this)).catch(function (error) {
            console.log(error)
          }.bind(this))
        } else {
          return HTTP.post('learning_portal_training/' + this.training.id, formData, config)
        }
      },
      onSaved() {
        this.dataOriginal = null
        this.close()
      },
      deleteTraining() {
        return HTTP.delete('learning_portal_training/' + this.training.id)
      },
      onDeleted() {
        this.dataOriginal = null
        this.close()
      },
      close() {
        this.$router.push({ name: 'LearningPortalTrainingsIndex' })
      },
      getLessonPrefix(index) {
        return this.t('lessons.lesson-prefix') + ' ' + (index + 1)
      },
      addLesson() {
        this.training.lessons.push({
          'title': '',
          'quizes': [],
          'blocks': []
        })
      },
      editLesson(index) {
        this.editLessonIndex = index
      },
      removeLesson(index) {
        this.training.lessons.splice(index, 1)
        this.training.required_correct_answers = Math.min(this.training.required_correct_answers, this.amountOfQuizes)
      },
      cancelEditLesson() {
        this.editLessonIndex = null
      },
      addQuiz(type) {
        if (this.editLessonIndex !== null) {
          this.training.lessons[this.editLessonIndex].quizes.push({
            'type': type,
            'title': '',
            'options': [],
            'selectedAnswer': null,
            'selectedOption': null
          })
        }
      },
      moveUp(index) {
        let temp = this.training.lessons[this.editLessonIndex].quizes[index]
        this.removeQuiz(index)
        this.training.lessons[this.editLessonIndex].quizes.splice(index - 1, 0, temp)
      },
      moveDown(index) {
        let temp = this.training.lessons[this.editLessonIndex].quizes[index]
        this.removeQuiz(index)
        this.training.lessons[this.editLessonIndex].quizes.splice(index + 1, 0, temp)
      },
      removeQuiz(index) {
        this.training.lessons[this.editLessonIndex].quizes.splice(index, 1)
        this.training.required_correct_answers = Math.min(this.training.required_correct_answers, this.amountOfQuizes)
      },
      addOption(qindex) {
        if (this.training.lessons[this.editLessonIndex].quizes[qindex].selectedOption !== null) {
          this.training.lessons[this.editLessonIndex].quizes[qindex].options.push({
            'value': this.training.lessons[this.editLessonIndex].quizes[qindex].selectedOption,
            'is_answer': false,
          })
          this.training.lessons[this.editLessonIndex].quizes[qindex].selectedOption = null
        }
      },
      removeOption(qindex, oindex) {
        this.training.lessons[this.editLessonIndex].quizes[qindex].options.splice(oindex, 1)
      },
      radioChanged(event, qindex) {
        this.training.lessons[this.editLessonIndex].quizes[qindex].options.forEach((o, index) => {
          o.is_answer = index == event
        })
      },
      validateNumberField(param) {
        if (typeof this.training[param] !== 'number') {
          this.training[param] = 0
        } else {
          this.training[param] = Math.min(this.amountOfQuizes, parseInt(this.training[param]))
        }
      },
      allowedTriesChanged(event) {
        if (event && this.training.allowed_tries === -1) {
          this.training.allowed_tries = 1
          this.dataOriginal.allowed_tries = 1
        }
      }
    }
  }
</script>

<style scoped lang="scss">
.lessons-wrapper {
  position: relative;

  .drag-element {
    padding-right: 68px;
    padding-top: 12px;
    padding-bottom: 8px;
  }

  .title-label {
    white-space: nowrap;
    max-width: calc(100% - 24px);
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;

    b {
      margin-right: 8px;
    }
  }

  .lesson-trash {
    position: absolute;
    right: 44px;
    top: 10px;
    cursor: pointer;
  }

  .lesson-edit {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }

  .drag-disable-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #FFFFFF;
    opacity: 0.5;
  }  
}

.quizes-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 16px;
  border-top: 1px solid #E0E0E0;

  .quiz-content {

    .quiz-content-hint {
      margin-bottom: 8px;
      font-style: italic;

      .v-icon {
        margin-right: 8px;
      }
    }

    .add-option {
      display: flex;
      align-items: center;
      width: calc(100% - 40px);

      .v-icon {
        margin-bottom: -1px;
        margin-right: 8px;
      }
    }

    .selected-options-list-wrapper {
      display: flex;

      .selected-options-list {
        width: calc(100% - 30px);

        & > div {
          line-height: 40px;
        }

        .v-icon {
          margin-right: 8px;
        }
      }

      .selected-options-list-answers {
        width: 30px;

        .v-radio {
          height: 40px !important;
          margin: -1px 0 0 0 !important;
        }

        .v-input--checkbox {
          margin-top: 2px !important;
          height: 40px !important;
        }
      }
    }
  }
}

.edit-lesson-action-bar {
  position: relative;
  padding-top: 16px;
  text-align: right;

  &:before {
    content: "";
    position: absolute;
    left: -16px;
    top: 0;
    width: calc(100% + 32px);
    height: calc(100% + 16px);
    background: #F5F5F5;
  }
}

.remove-block {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  width: 56px;
  height: 56px;
  padding: 16px;
  border-left: 1px solid #E0E0E0;
  transition: background 0.3s;

  &:hover {
    background: #FFFFFF;
  }
}

.move-block {
  position: absolute;
  right: 56px;
  top: 0;
  width: 112px;
  height: 56px;
  text-align: right;

  & > span {
    cursor: pointer;
    display: inline-block;
    width: 56px;
    height: 56px;
    padding: 16px;
    border-left: 1px solid #E0E0E0;
    transition: background 0.3s;

    &:hover {
      background: #FFFFFF;
    }
  }
}

.allowed-tries-wrapper {
  display: flex;
  justify-content: space-between;

  & > p {
    line-height: 28px;
  }

  .v-input {
    max-width: 140px;
  }
}
</style>